<template>
  <div class="card d-print-none mb-2">
    <div class="card-body">
      <router-link :to="taskData.api_url+'/'+taskData.crop_id" class="mr-2 mb-2 btn btn-sm bg-gradient-primary" ><i class="feather icon-corner-up-left"></i> {{labels.get('label_back')}}</router-link>
      <router-link v-if="taskData.permissions.action_1"  :to="taskData.api_url+'/'+taskData.crop_id+'/inputs/'+taskData.form_id+'/add'" class="mr-2 mb-2 btn btn-sm bg-gradient-primary" ><i class="feather icon-plus-circle"></i> {{labels.get('action_1')}}</router-link>
      <button type="button" v-if="taskData.permissions.action_4" class="mr-2 mb-2 btn btn-sm bg-gradient-primary" onclick="window.print();"><i class="feather icon-printer"></i> {{labels.get('action_4')}}</button>
      <button type="button" v-if="taskData.permissions.action_5" class="mr-2 mb-2 btn btn-sm bg-gradient-primary" @click="systemFunctions.exportCsv(taskData.columnsInput,taskData.itemsFilteredInput,taskData.api_url.substring(1)+'_'+taskData.cropInfo.name+'_'+taskData.formInfo.name+'.csv')"><i class="feather icon-download"></i> {{labels.get('action_5')}}</button>
      <button type="button" v-if="taskData.permissions.action_8" class="mr-2 mb-2 btn btn-sm" :class="[show_column_controls?'bg-gradient-success':'bg-gradient-primary']" @click="show_column_controls = !show_column_controls"><i class="feather icon-command"></i> {{labels.get('action_8')}}</button>
      <button type="button" v-if="taskData.permissions.action_0" class="mr-2 mb-2 btn btn-sm bg-gradient-primary" @click="taskData.reloadItemsInput(taskData.paginationInput)"><i class="feather icon-rotate-cw"></i> {{labels.get('label_refresh')}}</button>
    </div>
  </div>
  <ColumnControl :url="taskData.api_url.substring(1)" :method="'list-from'" :columns="taskData.columnsInput"  v-if="show_column_controls"/>
  <div class="card mb-2">
    <div class="card-header d-print-none">
      {{taskData.cropInfo.name}}-&nbsp;{{taskData.formInfo.name}}
      <div>{{labels.get('label_task_input')}}</div>
    </div>
    <div class="card-body" style='overflow-x:auto;min-height:250px;'>
      <table class="table table-bordered">
        <thead class="table-active">
        <tr>
          <th class="position-relative align-middle d-print-none">{{labels.get('label_action')}}</th>
          <template v-for="(column,key) in taskData.columnsInput.all">
            <th class="position-relative align-middle" v-if="taskData.columnsInput.hidden.indexOf(key)<0" :key="'th_'+key">
              <ColumnSort :columns="taskData.columnsInput" :sortKey="key" :position="'left:5px'"  :onChangeSort="taskData.setFilteredItemsInput" v-if="taskData.permissions.action_6 && column.sortable"/>
              <div class=" text-center " style="width:calc(100% - 33px);margin-left:17px">
                {{ column.label }}
              </div>
              <ColumnFilter :column="column" :position="'right:5px'"  :onChangeFilter="taskData.setFilteredItemsInput" v-if="taskData.permissions.action_6 && column.filterable"/>
            </th>
          </template>
        </tr>
        </thead>
        <tbody class="table-striped table-hover">
        <tr v-for="item in taskData.itemsFilteredInput" :key="'item_'+item.id">
          <td class="col_1 d-print-none">
            <button class="btn btn-sm bg-gradient-primary dropdown-toggle waves-effect waves-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{labels.get('label_action')}}</button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(0px, 38px, 0px);">
              <router-link v-if="taskData.permissions.action_0"  :to="taskData.api_url+'/'+taskData.crop_id+'/inputs/'+taskData.form_id+'/details/'+item.id" class="dropdown-item text-info btn-sm" ><i class="feather icon-camera"></i> {{labels.get('label_details')}}</router-link>
              <router-link v-if="taskData.permissions.action_2"  :to="taskData.api_url+'/'+taskData.crop_id+'/inputs/'+taskData.form_id+'/edit/'+item.id" class="dropdown-item text-info btn-sm" ><i class="feather icon-edit"></i> {{labels.get('label_edit')}}</router-link>
            </div>
          </td>
          <template v-for="(column,key) in taskData.columnsInput.all">
            <td :class="((['id','ordering'].indexOf(key) != -1)?'text-right':'')+(column.class?(' '+column.class):'col_9')" v-if="taskData.columnsInput.hidden.indexOf(key)<0" :key="'td_'+key">
              <template v-if="key=='options'" v-for="option in (item[key]?item[key].split('\r\n'):[])">
                  {{option}}<br>
                </template>
              <template v-else>{{item[key]}}</template>
            </td>
          </template>

        </tr>
        </tbody>
      </table>
      <Pagination :items = "taskData.itemsInput" :onChangePageOption="taskData.reloadItemsInput" :pagination="taskData.paginationInput"/>
    </div>
  </div>
</template>
<script setup> 

    import systemFunctions from "@/assets/systemFunctions";

    import labels from '@/labels'  
    import { inject,ref } from 'vue'
    import {useRouter} from 'vue-router';

    import ColumnControl from '@/components/ColumnControl.vue';
    import ColumnSort from '@/components/ColumnSort.vue';
    import ColumnFilter from '@/components/ColumnFilter.vue';
    import Pagination from '@/components/Pagination.vue';


    const router =useRouter()
    let taskData = inject('taskData')
    let show_column_controls=ref(false)
    const setColumns=()=>{
      let columns={}
      let key='id';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:true,
        filterable:true,
        sortable:true,
        type:'number',
        filter:{from:'',to:''},
        class:'col_1'
      };
      key='type';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:true,
        filterable:true,
        sortable:true,
        type:'dropdown',
        filter:{from:'',to:'',options:[
            {label:"Text",value:'text'},
            {label:"TextArea",value:'textarea'},
            {label:"Image",value:'image'},
            {label:"Date",value:'date'},
            {label:"DropDown",value:'dropdown'},
            {label:"CheckBox",value:'checkbox'},
          ],},
        class:'col_1'
      };
      key='name';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:false,
        filterable:true,
        sortable:true,
        type:'text',
        filter:{from:'',to:''}
      };
      key='options';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:false,
        filterable:true,
        sortable:true,
        type:'text',
        filter:{from:'',to:''}
      };
      key='mandatory';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:true,
        sortable:true,
        filterable:true,
        type:'dropdown',
        filter:{from:'',to:'',options:[{label:"Yes",value:'Yes'},{label:"No",value:'No'}]},
        class:'col_1'
      };
      key='ordering';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:true,
        filterable:false,
        sortable:true,
        type:'number',
        filter:{from:'',to:''},
        class:'col_1'
      };
      key='status';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:true,
        sortable:true,
        filterable:true,
        type:'dropdown',
        filter:{from:'',to:'',options:[{value:'Active',label:'Active'},{value:'In-Active',label:'In-Active'}]},
        class:'col_1'
      };
      key='created_at';
      columns[key]={
        label: labels.get('label_'+key),
        hideable:true,
        filterable:true,
        sortable:true,
        type:'date',
        filter:{from:'',to:''}
      };
      taskData.columnsInput.all=columns
    }
    setColumns();

</script>

