export default {
    label_task:{
        en:"Delivery Info",
        bn:'ব্যবহারকারীরা'
    },
    label_season:{
        en:"Season",
        bn:"কর্মচারী আইডি"
    },
    label_new_task:{
        en:"Add New Variety ",
        bn:'নতুন ব্যবহারকারী'
    },
    label_edit_task:{
        en:"Edit Variety",
        bn:'সম্পাদনা ব্যবহারকারী'
    },
    label_details_task:{
        en:"Details of Variety",
        bn:'ব্যবহারকারীর বিবরণ'
    },
    label_crop_type_name:{
        en:"Type",
        bn:'ব্যবহারকারীর বিবরণ'
    },
    label_retrial:{
        en:"Retrial",
        bn:"কর্মচারী আইডি"
    },
    label_num_seasons:{
        en:"#Seasons",
        bn:"কর্মচারী আইডি"
    },
    label_rnd_ordering:{
        en:"ordering",
        bn:"কর্মচারী আইডি"
    },
    label_season_ids:{
        en:"Seasons",
        bn:"কর্মচারী আইডি"
    },
    label_seasons:{
        en:"Seasons",
        bn:"কর্মচারী আইডি"
    },
    label_crop_name:{
        en:"Crop",
        bn:"কর্মচারী আইডি"
    },
    label_rnd_code:{
        en:"R&D Code",
        bn:"কর্মচারী আইডি"
    },

}